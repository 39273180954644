import React from "react"
import SEO from "../../components/seo"
import Header from "../../components/header"

import WorkFooter from "../../components/portfolioItems/worksFooter"


import Herospace from "../../../assets/images/portfolio_items/residenz/residenz.png"
import residenz1 from "../../../assets/images/portfolio_items/residenz/residenz1.png"
import residenz2 from "../../../assets/images/portfolio_items/residenz/residenz2.png"
import residenz3 from "../../../assets/images/portfolio_items/residenz/residenz3.png"
import residenz4 from "../../../assets/images/portfolio_items/residenz/residenz4.png"
import residenz5 from "../../../assets/images/portfolio_items/residenz/residenz5.png"

export default () => 
<div>
    <SEO title={'ICON Worldwide | Residenz Forch - Web Project Forch'} 
    description="A unique print and design project to showcase the architecture of a luxury, senior living residence: Residenz Forch. Discover more on ICON's portfolio! "
    canonical={'https://icon-worldwide.com/works/residenz-forch'} />
        <Header bgcolor="#292929"/>
    <div id="portfolio-item">
        <div className="herospace">
            <img src={Herospace} alt="Residenz Forch, ICON Worldwide, print and web project, portfolio" title="ICON Worldwide portfolio, print and web project, Residenz Forch"/>
            <div className="title"><h1>Residenz Forch<span></span></h1>
            <h2>A unique print and web design project</h2>
            </div>
        </div>
        <div className="portfolio-inner">
        <p>Part of the Zolliger Stiftung community, the website is designed to showcase the unique architecture of this luxury, senior living residence. The project is complemented by a print campaign</p>
        <div className="portfolio-images">
            <img src={residenz1} alt="Residenz Forch, ICON Worldwide, print and web project, portfolio" title="ICON Worldwide portfolio, print and web project, Residenz Forch"/>
            <img src={residenz4} alt="Residenz Forch, ICON Worldwide, print and web project, portfolio" title="ICON Worldwide portfolio, print and web project, Residenz Forch"/>
            <img src={residenz2} alt="Residenz Forch, ICON Worldwide, print and web project, portfolio" title="ICON Worldwide portfolio, print and web project, Residenz Forch"/>
            <img src={residenz3} alt="Residenz Forch, ICON Worldwide, print and web project, portfolio" title="ICON Worldwide portfolio, print and web project, Residenz Forch"/>
            <img src={residenz5} alt="Residenz Forch, ICON Worldwide, print and web project, portfolio" title="ICON Worldwide portfolio, print and web project, Residenz Forch"/>
        </div>
        <WorkFooter previous="zollinger-stiftung" next="project-firefly"/>
        </div>
    </div>
</div>